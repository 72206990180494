<template>
    <div class="card-container" v-if="currentQuarterTargetExceeded">
        <v-overlay
            :opacity="0.15"
            :value="loader"
            absolute
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-card elevation="6" class="mx-auto">
            <v-card-text>test</v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
	name: "GrossMarginExceeded",
    props: ['conditions'],
    data(){
        return {
            currentQuarterMissingGrossMargin: 0,
            currentQuarterTargetExceeded: false,
            errorMessage: null,
            headerText1: '',
            headerText2: '',
            label: '',
            loader: true,
            loadingError: false,
            requiredSales: []
        }
    },
    mounted() {
        const _this = this
        setTimeout(() => {
            _this.loader = false
        },5000)
    }
}
</script>

<style scoped>
.card-container {
    position: relative;
}
</style>